import React, { useEffect } from 'react'
import * as ReactDOM from 'react-dom'
import { useAuthBroadcastObserver, onLogout } from 'broadcastChannels/auth'

const BroadcastObserverPack = () => {
    useAuthBroadcastObserver()
    useRegisterLogoutElements()

    return <div id="broadcast-observer-mounted" />
}

const useRegisterLogoutElements = () => {
    useEffect(() => {
        const logoutElements = Array.from(
            document.querySelectorAll('[data-logout-button]')
        )

        for (const logoutElement of logoutElements) {
            logoutElement.addEventListener('click', onLogout)
        }
    }, [])
}

function mountBroadcastObserverPack(callback) {
    const element = document.getElementById('broadcast-observer')

    callback(element)
}

mountBroadcastObserverPack((element) => {
    ReactDOM.render(<BroadcastObserverPack />, element)
})
